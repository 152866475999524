exports.components = {
  "component---src-pages-2020-alg-js": () => import("./../../../src/pages/2020-alg.js" /* webpackChunkName: "component---src-pages-2020-alg-js" */),
  "component---src-pages-2021-alg-js": () => import("./../../../src/pages/2021-alg.js" /* webpackChunkName: "component---src-pages-2021-alg-js" */),
  "component---src-pages-2022-alg-js": () => import("./../../../src/pages/2022-alg.js" /* webpackChunkName: "component---src-pages-2022-alg-js" */),
  "component---src-pages-2023-alg-js": () => import("./../../../src/pages/2023-alg.js" /* webpackChunkName: "component---src-pages-2023-alg-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-canopy-hub-js": () => import("./../../../src/pages/canopy-hub.js" /* webpackChunkName: "component---src-pages-canopy-hub-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-key-projects-js": () => import("./../../../src/pages/key-projects.js" /* webpackChunkName: "component---src-pages-key-projects-js" */),
  "component---src-pages-media-resource-js": () => import("./../../../src/pages/media-resource.js" /* webpackChunkName: "component---src-pages-media-resource-js" */),
  "component---src-pages-our-heritage-js": () => import("./../../../src/pages/our-heritage.js" /* webpackChunkName: "component---src-pages-our-heritage-js" */),
  "component---src-pages-our-model-js": () => import("./../../../src/pages/our-model.js" /* webpackChunkName: "component---src-pages-our-model-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */)
}

